// ** Reducers Imports
import layout from './layout'
import auth from './authentication'

const rootReducer = {
  auth,
  layout
}

export default rootReducer
