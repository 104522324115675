// ** Core JWT Import
import useJwt from '@src/@core/auth/jwt/useJwt'

const { jwt } = useJwt({
  /*  loginEndpoint: 'https://api.commerce.infra-nfc.com/auth',*/
    loginEndpoint: 'https://api.stats.casquesbleus.fr/auth',
    registerEndpoint: '/jwt/register',
    /*refreshEndpoint: 'https://api.commerce.infra-nfc.com/api/token/refresh',*/
    refreshEndpoint: 'http://api.stats.casquesbleus.fr/api/token/refresh',
    logoutEndpoint: '/jwt/logout',

    // ** This will be prefixed in authorization header with token
    // ? e.g. Authorization: Bearer <token>
    tokenType: 'Bearer',

    // ** Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: 'accessToken',
    storageRefreshTokenKeyName: 'refreshToken'
})

export default jwt
